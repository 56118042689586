<template lang="pug">
input(v-model='query' type='text' :placeholder='placeholder')
</template>

<script setup lang="ts">
const route = useRoute()
const $searchContext = inject('$searchContext', null)
const emit = defineEmits(['query'])

const {
  placeholder = '',
  autoSearch = true,
  updateRoute = true,
} = defineProps<{
  placeholder?: string
  autoSearch?: boolean
  updateRoute?: boolean
}>()

let routerTimeout: ReturnType<typeof setTimeout> | undefined

const query = computed({
  get() {
    return $searchContext.state.value.searchParameters.query
  },
  set(val: string) {
    // Trigger the search so we can have instant results.
    $searchContext.setQuery(val)

    if (autoSearch) $searchContext.search()

    if (!updateRoute) return emit('query', val)

    // We don't actually use this as where we use this sets the update prop to false but
    // If we set then we want to clear the previous iteration
    clearTimeout(routerTimeout)

    // Debounce the router stuff so we don't DDOS the router.
    routerTimeout = setTimeout(() => {
      const { mod } = route.query

      const routeObj = {
        name: route.name,
        params: {
          ...route.params,
        },
        query: {
          ...(mod && { mod }),
          ...$searchContext.createRouteQuery(),
        },
      }

      navigateTo(routeObj)
    }, 1000)
  },
})
</script>
